/* Sidebar (Desktop Layout) */
.navbar {
    position: fixed;
    top: 20px;
    left: 20px;
    height: calc(100vh - 40px);
    background-color: #1a1a1a;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    z-index: 1000;
    transition: width 0.3s ease;
    width: 230px; /* Default width */
}

    .navbar.collapsed {
        width: 60px; /* Collapsed width */
    }

/* Toggle Button */
.toggle-button {
    position: fixed;
    bottom: 20px; /* Align with bottom of the sidebar */
    left: 240px; /* Slight overlap with sidebar edge */
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s ease, transform 0.2s ease;
    z-index: 1100; /* Higher than the sidebar */
}

.navbar.collapsed + .toggle-button {
    left: 80px; /* Adjusted for collapsed width */
}

.toggle-button:hover {
    background-color: #555;
}

/* Logo Section */
.navbar-logo {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 20px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .navbar-logo.hidden {
        display: none;
    }

/* Links Section */
.navbar-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.navbar-link {
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

    .navbar-link:hover {
        background-color: #333;
        color: #fda085;
    }

.emoji-icon {
    font-size: 20px;
}

/* Account Section */
.navbar-account {
    margin-top: auto;
    padding: 20px 10px;
    border-top: 1px solid #444;
    text-align: left;
    cursor: pointer; /* Indicate clickable area */
    flex-shrink: 0;
    transition: background-color 0.3s ease;
}

    .navbar-account:hover {
        background-color: #333; /* Highlight on hover */
    }

.navbar-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fda085;
}

.navbar-username {
    font-size: 16px;
    color: #fff;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.navbar.collapsed .navbar-username {
    opacity: 0; /* Hide text in collapsed mode */
    visibility: hidden;
    width: 0;
    overflow: hidden;
}

/* Logout Confirmation Modal */
.logout-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above everything */
}

.logout-modal {
    background-color: #1a1a1a;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    max-width: 300px;
    width: 90%;
}

    .logout-modal h3 {
        margin-bottom: 10px;
        font-size: 1.5rem;
    }

    .logout-modal p {
        margin-bottom: 20px;
        font-size: 1rem;
    }

.logout-modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.confirm-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .confirm-button:hover {
        background-color: #d32f2f; /* Darker red on hover */
    }

.cancel-button {
    background-color: #555;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

    .cancel-button:hover {
        background-color: #777; /* Slightly lighter gray on hover */
    }

.navbar.collapsed ~ .main-content {
    margin-left: 80px;
}

/* Prevent Horizontal Scroll */
body {
    margin: 0;
    overflow-x: hidden;
    background-color: #121212;
}
.gradient-text {
    background: linear-gradient(90deg, #ff7eb3, #fda085, #ffd700, #cab903);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnimation 3s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
