/* Home container styles */
.home-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
    height: 100vh; /* Full viewport height */
    background-color: transparent;
    color: #e5e5e5;
    overflow: hidden; /* Prevent scrolling */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

/* Home content styles */
.home-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    z-index: 1; /* Ensure content is above the video */
    width: 100%;
    max-width: 600px; /* Constrain the width */
    padding: 20px; /* Add some padding */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Video wrapper styles */
.video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Hide overflow edges */
    z-index: -1; /* Place it behind all other elements */
    background-color: #000000; /* Black fallback to prevent flashes of white */
}

/* Video element styles */
.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the video */
    min-width: 100%;
    min-height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    filter: brightness(70%) blur(0px); /* Adjust brightness for blending */
    background-color: #000000; /* Black background during opacity transitions */
}

/* Home title and subtitle */
.home-title {
    font-size: 2.5rem;
    color: #ffffff;
    margin: 0 0 15px 0; /* Adjust spacing */
}

.home-subtitle {
    font-size: 1.2rem;
    color: #bbbbbb;
    margin: 0 0 25px 0; /* Adjust spacing */
}

.gradient-text {
    background: linear-gradient(135deg, #cab903, #ffd700); /* Shiny gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Login button styles */
.home-login-button {
    background: linear-gradient(135deg, #cab903, #ffd700);
    color: #000000;
    font-weight: bold;
    font-size: 1rem;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.home-login-button:hover {
    transform: translateY(-2px); /* Subtle lift effect */
    box-shadow: 0 0 15px 5px rgba(255, 215, 0, 0.6); /* Glow effect */
}

.home-login-button:active {
    background: linear-gradient(135deg, #8b7500, #cab903);
    transform: translateY(0); /* Reset lift effect */
}

/* Terms and Privacy Policy Agreement */
.home-agreement {
    font-size: 0.85rem;
    color: #ddd;
    margin-top: 10px;
    line-height: 1.3;
}

/* Clickable text for Terms and Privacy Policy */
.clickable-text {
    color: #ffd700;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.clickable-text:hover {
    color: #ffcc00;
    text-shadow: 0 1px 2px rgba(255, 255, 255, 0.6);
}

/* Responsive Design */

/* Adjustments for tablets */
@media (max-width: 768px) {
    .home-title {
        font-size: 2rem;
    }

    .home-subtitle {
        font-size: 1rem;
    }

    .home-login-button {
        font-size: 0.9rem;
        padding: 10px 20px;
    }
}

/* Adjustments for phones */
@media (max-width: 480px) {
    .home-title {
        font-size: 1.8rem;
    }

    .home-subtitle {
        font-size: 0.9rem;
    }

    .home-login-button {
        font-size: 0.85rem;
        padding: 8px 16px;
    }
}


/* Video wrapper styles */
.video-wrapper {
    position: fixed; /* Keep the video fixed in the background */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* Hide overflow edges */
    z-index: -1; /* Place it behind all other elements */
    background-color: #000000; /* Black fallback to prevent flashes of white */
}

/* Video element styles */
.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the video */
    min-width: 100%; /* Ensure video covers the full width */
    min-height: 100%; /* Ensure video covers the full height */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    filter: brightness(70%) blur(0px); /* Adjust brightness for blending */
    background-color: #000000; /* Black background during opacity transitions */
}

/* Audio icon styles */
.audio-icon {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Move to bottom-left */
    color: #000;
    font-size: 24px; /* Large enough for easy interaction */
    padding: 10px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

    .audio-icon:hover {
        background: #cab903; /* Slightly darker gold */
        transform: scale(1.1); /* Slight zoom effect */
    }

    .audio-icon:active {
        transform: scale(0.95); /* Click effect */
    }

/* Home title and subtitle */
.home-title {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 15px; /* Adjust spacing */
}

.gradient-text {
    background: linear-gradient(135deg, #cab903, #ffd700); /* Shiny gradient */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-subtitle {
    font-size: 1.2rem;
    color: #bbbbbb;
    margin-top: 0;
    margin-bottom: 25px; /* Adjust spacing */
}

/* Login button styles */
.home-login-button {
    background: linear-gradient(135deg, #cab903, #ffd700);
    color: #000000;
    font-weight: bold;
    font-size: 1rem;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

    .home-login-button:hover {
        transform: translateY(-1px); /* Lift effect */
        box-shadow: 0 0 15px 5px rgba(255, 215, 0, 0.6); /* Glow effect */
    }

    .home-login-button:active {
        background: linear-gradient(135deg, #8b7500, #cab903);
        transform: translateY(0); /* Reset lift effect */
    }

/* Popup Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the modal is above everything */
    cursor: pointer;
}

.modal-content {
    position: relative;
    text-align: center;
}

.modal-image {
    max-width: 90%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
    border-radius: 10px; /* Rounded corners for image */
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

    .modal-close-button:hover {
        color: #ffcc00; /* Highlight on hover */
    }

.modal-click-to-continue {
    margin-top: 10px;
    color: #ffffff;
    font-size: 1rem;
    font-style: italic;
    opacity: 0.8;
    cursor: pointer;
}

/* Terms Modal Overlay */
.terms-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Overlay stays on top */
}

/* Terms Modal Content */
.terms-modal-content {
    background-color: #1a1a1a;
    color: #e5e5e5;
    padding: 20px;
    border-radius: 8px;
    max-height: 80vh; /* Scrollable content */
    overflow-y: auto; /* Enable scrolling */
    width: 90%;
    max-width: 600px;
    position: relative; /* Important for positioning the close button */
}

.terms-modal-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.terms-modal-body {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
}

.terms-modal-close-button {
    position: absolute; /* Position relative to .terms-modal-content */
    top: 10px; /* Stay at the top of the content */
    right: 10px; /* Stay at the right of the content */
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
}

    .terms-modal-close-button:hover {
        color: #ffcc00; /* Highlight on hover */
    }

/* Floating Join Image */
.floating-join-image {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensure it's above other elements */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    animation: floatUpDown 2.5s ease-in-out infinite; /* Floating animation */
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.floating-image {
    width: 80px; /* Set a small size for the image */
    height: auto;
    border-radius: 10px; /* Optional: Rounded corners */
}

.floating-join-text {
    margin-top: 8px;
    color: #ffcc00; /* Gold color */
    font-size: 0.9rem;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow */
    text-transform: uppercase;
}

/* Floating Animation */
@keyframes floatUpDown {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}
.gradient-text {
    background: linear-gradient(90deg, #ff7eb3, #fda085, #ffd700, #cab903);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnimation 3s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Terms and Privacy Policy Agreement */
.home-agreement {
    font-size: 0.85rem; /* Smaller font size */
    color: #ddd; /* Light grey for readability */
    text-align: center;
    margin-top: 10px;
    line-height: 1.3; /* Compact line spacing */
}

/* Clickable text for Terms and Privacy Policy */
.clickable-text {
    color: #ffd700; /* Bright yellow color for visibility */
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline; /* Underline to indicate interactivity */
    transition: color 0.3s ease;
}

    .clickable-text:hover {
        color: #ffcc00; /* Slightly lighter yellow on hover for effect */
        text-shadow: 0 1px 2px rgba(255, 255, 255, 0.6); /* Subtle glow */
    }

.home-potential {
    font-size: 1.5rem; /* Larger than default but smaller than h1 */
    color: #e5e5e5; /* Light grey for readability */
    margin-top: 10px; /* Small margin above */
    margin-bottom: 20px; /* Adjust spacing below */
    text-align: center; /* Center-align text */
}

/* General Mobile Styles */
@media (max-width: 768px) {
    /* Home container adjustments */
    .home-container {
        padding-top: 50px; /* Reduce top padding */
        align-items: stretch; /* Fill the screen width */
    }

    /* Home title and subtitle adjustments */
    .home-title {
        font-size: 2rem; /* Smaller title size for tablets */
        margin-bottom: 5px;
    }

    .home-subtitle {
        font-size: 1rem; /* Smaller subtitle size */
        margin-bottom: 15px;
    }

    .gradient-text {
        font-size: 1.2rem; /* Adjust gradient text size */
    }

    /* Login button adjustments */
    .home-login-button {
        font-size: 0.9rem; /* Slightly smaller font size */
        padding: 10px 20px; /* Adjust padding */
    }

    /* Audio icon adjustments */
    .audio-icon {
        font-size: 20px; /* Smaller icon */
        bottom: 15px; /* Adjust positioning */
        left: 15px;
    }

    /* Floating Join Image adjustments */
    .floating-join-image {
        bottom: 15px; /* Adjust position for smaller screens */
        right: 15px;
    }

    .floating-image {
        width: 60px; /* Smaller image */
    }

    .floating-join-text {
        font-size: 0.8rem; /* Reduce text size */
    }

    /* Terms and Privacy Policy adjustments */
    .home-agreement {
        font-size: 0.75rem; /* Smaller font for compact screens */
        margin-top: 5px;
    }

    .clickable-text {
        font-size: 0.8rem; /* Adjust clickable text size */
    }
}

/* Further adjustments for phones */
@media (max-width: 480px) {
    /* Home title and subtitle adjustments */
    .home-title {
        font-size: 1.8rem; /* Reduce title size for phones */
    }

    .home-subtitle {
        font-size: 0.9rem; /* Compact subtitle */
        margin-bottom: 10px;
    }

    /* Login button adjustments */
    .home-login-button {
        font-size: 0.85rem;
        padding: 8px 16px;
    }

    /* Audio icon adjustments */
    .audio-icon {
        font-size: 18px; /* Smaller icon size */
        bottom: 10px;
        left: 10px;
    }

    /* Floating Join Image adjustments */
    .floating-join-image {
        bottom: 10px;
        right: 10px;
    }

    .floating-image {
        width: 50px; /* Compact image size */
    }

    .floating-join-text {
        font-size: 0.7rem; /* Reduce text size */
    }

    /* Terms and Privacy Policy adjustments */
    .home-agreement {
        font-size: 0.7rem; /* Compact font size */
    }

    .clickable-text {
        font-size: 0.75rem; /* Adjust clickable text size */
    }

    /* Terms Modal adjustments */
    .terms-modal-content {
        font-size: 0.8rem; /* Compact text */
        padding: 10px;
    }

    .terms-modal-title {
        font-size: 1.5rem; /* Adjust modal title size */
    }
}
