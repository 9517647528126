/* Main Content when Navbar is visible */
.app-container.with-navbar .main-content {
    margin-left: 250px;
    padding: 20px;
    transition: margin-left 0.3s ease;
}

/* Main Content when Navbar is hidden */
.app-container.no-navbar .main-content {
    margin-left: 0;
    padding: 0; /* Adjust as needed */
}

/* Adjust for collapsed Navbar */
.app-container.with-navbar .main-content.collapsed {
    margin-left: 80px;
}
