.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #f5f5f5;
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 1200px; /* Match the width for all sections */
    margin: 0 auto; /* Center the dashboard */
    box-sizing: border-box;
}

.score-header {
    width: 100%; /* Full width for the header */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1a1a1a;
    border: 2px solid #f5d742;
    border-radius: 10px;
    color: #f5f5f5;
    font-size: 1.2rem;
    box-sizing: border-box;
    max-width: 1200px; /* Consistent max width */
    margin-left: 250px; /* Account for sidebar */
}

.score-header h2 {
    font-size: 1.5rem;
    color: #f5f5f5;
    margin: 0;
}

.inventory-summary {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

/* Main Content */
.dash-content {
    display: flex;
    justify-content: flex-start; /* Align main content to the left */
    align-items: flex-start;
    width: calc(100% - 0px); /* Adjust width to account for navbar */
    max-width: 1200px; /* Match with header and footer */
    gap: 20px;
    margin: 20px 0; /* Add spacing above and below */
    box-sizing: border-box;
    padding: 0 20px; /* Match header/footer padding */
}

.avatar-container {
    flex: 1;
    text-align: center;
}

.avatar-image {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}

.profile-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Profile Analysis and MAX Activities */
.profile-analysis,
.max-activities {
    flex: 1;
    padding: 15px;
    background: #1a1a1a;
    border-radius: 10px; /* Removed border from here */
    color: #f5f5f5;
    width: 100%;
    box-sizing: border-box;
}

/* Border removed from Profile Analysis and MAX Activities containers */

/* Individual activity items with border */
.activity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1a1a1a;
    border-radius: 10px;
    padding: 10px 15px;
    border: 2px solid #f5d742; /* Border remains here */
    margin-bottom: 15px;
}

.activity-btn {
    background-color: #f5d742;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    color: #101010;
    font-weight: bold;
    cursor: pointer;
}

.activity-points {
    display: flex;
    align-items: center;
    color: #32cd32;
    font-weight: bold;
}

.activity-points .arrow {
    margin-right: 5px;
    color: #32cd32;
}

/* Footer Content */
.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Match the width of other sections */
    max-width: 1200px; /* Align with the header and main content */
    gap: 20px;
    padding: 0 20px; /* Match the header and main content padding */
    box-sizing: border-box;
    margin-left: 250px; /* Account for the left sidebar */
}

.what-others-say,
.profile-ratings {
    flex: 1;
    text-align: center;
    background: #1a1a1a;
    padding: 20px;
    box-sizing: border-box;
    /* No borders here */
}
