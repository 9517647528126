/* src/styles/Leaderboard.css */

.leaderboard {
    padding: 20px;
    padding-left: 250px; /* Adjust based on expanded sidebar width */
    background-color: #121212;
    color: #ffffff;
    min-height: 100vh;
    text-align: center;
    transition: padding-left 0.3s ease; /* Smooth transition for content shift */
    width: 100%;
    max-width: 2000px;
}

    .leaderboard.collapsed {
        padding-left: 80px; /* Adjust for collapsed sidebar width */
    }


    .leaderboard h1 {
        font-size: 2rem;
        color: #1DE9B6; /* Accent color for leaderboard heading */
        margin-bottom: 20px;
    }

.leaderboard-table {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-collapse: collapse;
}

    .leaderboard-table th,
    .leaderboard-table td {
        padding: 10px;
        text-align: center;
        border: 1px solid #333333; /* Subtle borders */
    }

    .leaderboard-table th {
        background-color: #1f1f1f; /* Darker background for header */
        color: #1DE9B6;
        font-weight: bold;
    }

    .leaderboard-table td {
        background-color: #1a1a1a; /* Slightly lighter row background */
        color: #cfcfcf;
    }

    .leaderboard-table tr:nth-child(even) {
        background-color: #222222; /* Alternate row background for readability */
    }

    .leaderboard-table .current-user-row {
        background-color: #ffa500;
        color: #000;
        font-weight: bold;
    }

    .leaderboard-table .divider-row {
        background-color: #333;
        color: #fff;
        text-align: center;
        font-weight: bold;
    }

/* Responsive Adjustments */
@media (max-width: 768px) {
    .leaderboard {
        padding-left: 20px; /* Ensure adequate padding on mobile */
    }

        .leaderboard h1 {
            font-size: 1.5rem;
        }

    .leaderboard-table {
        font-size: 0.9rem;
    }
}
